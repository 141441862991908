.swiper-slide {
    max-width: 150px;
}

.swiper {
    padding-left: 50px;
    padding-right: 50px;
}

.swiper-button-prev {
    justify-content: flex-start;
    width: 32px;
    left: 0px;
    height: 100%;
    top: auto;
    bottom: 0;
    background-color: white;

    color: rgb(221, 107, 32);
}

.swiper-button-next {
    justify-content: flex-end;
    width: 32px;
    right: 0px;
    height: 100%;
    top: auto;
    bottom: 0;
    background-color: white;
    outline: none;

    color: rgb(221, 107, 32);
}

.swiper-button-prev:active,
.swiper-button-next:active {
    outline: none;
    box-shadow: none;
}